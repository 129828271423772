<div id="menu-sidebar" class="sidebar" [class.open]="!menu_collapsed">
  <div class="logo-details p__title-menu">
    <div class="logo_name">
      <p-image
        src="../../assets/images/Marinete_Logotipo.png"
        alt="Image"
        imageClass="w-8 vertical-align-middle"
        styleClass="logo_name"
      />
    </div>
    <img
      [src]="
        menu_collapsed
          ? '../../../../assets/images/icons/right_arrow_collapse.svg'
          : '../../../../assets/images/icons/left_arrow_collapse.svg'
      "
      class="icon-collapse"
      id="btn"
      (click)="menu_collapsed = !menu_collapsed"
    />
  </div>
  <ul class="nav-list">
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a
        routerLink="admin/home"
        class="p__menu-item"
        [class.active]="showMenu === '/admin/home'"
      >
        <i
          class="icon-svg icon-start m__menu-item"
          [class.icon_active]="showMenu === '/admin/home'"
        ></i>
        <span class="links_name">Início</span>
      </a>
      <span class="tooltip">Início</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        routerLink="professional/home"
        class="p__menu-item"
        [class.active]="showMenu === '/professional/home'"
      >
        <i
          class="icon-svg icon-start m__menu-item"
          [class.icon_active]="showMenu === '/professional/home'"
        ></i>
        <span class="links_name">Início</span>
      </a>
      <span class="tooltip">Início</span>
    </li>
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a
        routerLink="profile/view"
        class="p__menu-item"
        [routerLinkActive]="['active']"
        #rla_profile="routerLinkActive"
      >
        <i
          class="icon-svg icon-person m__menu-item"
          [class.icon_active]="rla_profile.isActive"
        ></i>
        <span class="links_name">Meus Dados</span>
      </a>
      <span class="tooltip">Meus Dados</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        (click)="sidebarDropdownBankProfessional.toggle($event)"
        class="p__menu-item cursor-pointer"
        [class.active]="
          showMenu === '/profile/view' ||
          showMenu === '/professional/bank/register'
        "
      >
        <i
          class="icon-svg icon-person m__menu-item"
          [class.icon_active]="
            showMenu === '/profile/view' ||
            showMenu === '/professional/bank/register'
          "
        ></i>
        <span class="links_name"
          >Meus Dados
          <i
            class="pi pi-angle-right text-primary icon__menu-sidebar-dropdown--position"
            [class.icon__menu-sidebar-dropdown--color-active]="
              showMenu === '/profile/view' ||
              showMenu === '/professional/bank/register'
            "
          ></i
        ></span>
      </a>
      <p-menu
        #sidebarDropdownBankProfessional
        [model]="navbar_dropdown_my_data"
        [popup]="true"
        styleClass="sidebar__menu-dropdown--position"
      >
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link p-2">
            <span [class]="item.icon"></span>
            <span class="text-xs font-normal sidebar__menu-dropdown--text">{{
              item.label
            }}</span>
          </a>
        </ng-template>
      </p-menu>
      <span class="tooltip">Meus Dados</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        routerLink="professional/service-config/residence"
        class="p__menu-item"
        [routerLinkActive]="['active']"
        #rla_service_modes="routerLinkActive"
      >
        <i
          class="icon-svg icon-option-config m__menu-item"
          [class.icon_active]="rla_service_modes.isActive"
        ></i>
        <span class="links_name">Modos de Atendimento</span>
      </a>
      <span class="tooltip">Modos de Atendimento</span>
    </li>
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a class="p__menu-item">
        <i class="icon-svg icon-unlock m__menu-item"></i>
        <span class="links_name">Dados de Acesso</span>
      </a>
      <span class="tooltip">Dados de Acesso</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a class="p__menu-item">
        <i class="icon-svg icon-chart m__menu-item"></i>
        <span class="links_name">Estatísticas</span>
      </a>
      <span class="tooltip">Estatísticas</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a class="p__menu-item">
        <i class="icon-svg icon-credit m__menu-item"></i>
        <span class="links_name">Pagamentos</span>
      </a>
      <span class="tooltip">Pagamentos</span>
    </li>
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a
        routerLink="admin/users/view"
        class="p__menu-item"
        [class.active]="showMenu === '/admin/users/view'"
      >
        <i
          class="icon-svg icon-health m__menu-item"
          [class.icon_active]="showMenu === '/admin/users/view'"
        ></i>
        <span class="links_name">Profissionais Inscritos</span>
      </a>
      <span class="tooltip">Profissionais Inscritos</span>
    </li>
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a
        routerLink="admin/pending-approval"
        class="p__menu-item"
        [class.active]="showMenu === '/admin/pending-approval'"
      >
        <i
          class="icon-svg icon-time-approval m__menu-item"
          [class.icon_active]="showMenu === '/admin/pending-approval'"
        ></i>
        <span class="links_name">Profissionais Pendentes</span>
      </a>
      <span class="tooltip">Profissionais Pendentes</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        routerLink="professional/appointments"
        class="p__menu-item"
        [class.active]="showMenu === '/professional/appointments'"
      >
        <i
          class="icon-svg icon-calendar m__menu-item"
          [class.icon_active]="showMenu === '/professional/appointments'"
        ></i>
        <span class="links_name">Agendamento</span>
      </a>
      <span class="tooltip">Agendamento</span>
    </li>
    <li *ngIf="this.userRole === this.role.ADMIN">
      <a
        (click)="sidebarDropdown.toggle($event)"
        class="p__menu-item cursor-pointer"
        [class.active]="
          showMenu === '/admin/users/professional/new' ||
          showMenu === '/admin/users/establishment/new'
        "
      >
        <i
          class="icon-svg icon-edit m__menu-item"
          [class.icon_active]="
            showMenu === '/admin/users/professional/new' ||
            showMenu === '/admin/users/establishment/new'
          "
        ></i>
        <span class="links_name"
          >Cadastrar
          <i
            class="pi pi-angle-right text-primary icon__menu-sidebar-dropdown--position"
            [class.icon__menu-sidebar-dropdown--color-active]="
              showMenu === '/admin/users/professional/new' ||
              showMenu === '/admin/users/establishment/new'
            "
          ></i
        ></span>
      </a>
      <p-menu
        #sidebarDropdown
        [model]="navbar_dropdown_items_admin"
        [popup]="true"
        styleClass="sidebar__menu-dropdown--position"
      >
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link p-2">
            <span [class]="item.icon"></span>
            <span class="text-xs font-normal sidebar__menu-dropdown--text">{{
              item.label
            }}</span>
          </a>
        </ng-template>
      </p-menu>
      <span class="tooltip">Cadastrar</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        (click)="sidebarDropdownProfessionalService.toggle($event)"
        class="p__menu-item cursor-pointer"
        [class.active]="
          showMenu === '/professional/register/service/new' ||
          showMenu === '/professional/register/service/view'
        "
      >
        <i
          class="icon-svg icon-health m__menu-item"
          [class.icon_active]="
            showMenu === '/professional/register/service/new' ||
            showMenu === '/professional/register/service/view'
          "
        ></i>
        <span class="links_name"
          >Serviços
          <i
            class="pi pi-angle-right text-primary icon__menu-sidebar-dropdown--position"
            [class.icon__menu-sidebar-dropdown--color-active]="
              showMenu === '/professional/register/service/new' ||
              showMenu === '/professional/register/service/view'
            "
          ></i
        ></span>
      </a>
      <p-menu
        #sidebarDropdownProfessionalService
        [model]="navbar_dropdown_items_professional_service"
        [popup]="true"
        styleClass="sidebar__menu-dropdown--position"
      >
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link p-2">
            <span [class]="item.icon"></span>
            <span class="text-xs font-normal sidebar__menu-dropdown--text">{{
              item.label
            }}</span>
          </a>
        </ng-template>
      </p-menu>
      <span class="tooltip">Serviços</span>
    </li>
    <li *ngIf="this.userRole === this.role.PROFESSIONAL">
      <a
        (click)="sidebarDropdownProfessionalProduct.toggle($event)"
        class="p__menu-item cursor-pointer"
        [class.active]="
          showMenu === '/professional/register/product/new' ||
          showMenu === '/professional/register/product/view'
        "
      >
        <i
          class="icon-svg icon-products m__menu-item"
          [class.icon_active]="
            showMenu === '/professional/register/product/new' ||
            showMenu === '/professional/register/product/view'
          "
        ></i>
        <span class="links_name"
          >Produtos
          <i
            class="pi pi-angle-right text-primary icon__menu-sidebar-dropdown--position"
            [class.icon__menu-sidebar-dropdown--color-active]="
              showMenu === '/professional/register/product/new' ||
              showMenu === '/professional/register/product/view'
            "
          ></i
        ></span>
      </a>
      <p-menu
        #sidebarDropdownProfessionalProduct
        [model]="navbar_dropdown_items_professional_product"
        [popup]="true"
        styleClass="sidebar__menu-dropdown--position"
      >
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center p-menuitem-link p-2">
            <span [class]="item.icon"></span>
            <span class="text-xs font-normal sidebar__menu-dropdown--text">{{
              item.label
            }}</span>
          </a>
        </ng-template>
      </p-menu>
      <span class="tooltip">Produtos</span>
    </li>
    <li class="divider">
      <p-divider />
    </li>
    <li>
      <a class="p__menu-item">
        <i class="icon-svg icon-help m__menu-item"></i>
        <span class="links_name">Ajuda</span>
      </a>
      <span class="tooltip">Ajuda</span>
    </li>
    <li>
      <a class="p__menu-item">
        <i class="icon-svg icon-term m__menu-item"></i>
        <span class="links_name">Termos e Condições</span>
      </a>
      <span class="tooltip">Termos e Condições</span>
    </li>
    <li>
      <a class="p__menu-item">
        <i class="icon-svg icon-privace m__menu-item"></i>
        <span class="links_name">Políticas de Privacidade</span>
      </a>
      <span class="tooltip">Políticas de Privacidade</span>
    </li>
    <li class="profile" (click)="logout()">
      <a class="profile-details" class="p__menu-item">
        <i class="icon-svg icon-logout m__menu-item"></i>
        <span class="links_name name">Sair</span>
      </a>
    </li>
  </ul>
</div>
